import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";

import Pace from "react-pace-progress";
import { connect } from "react-redux";

// Import custom components
import { setHeaderSpaceHeight } from "actions";
import NavBar from "./common/navbar";
import InstagramStories from "assets/images/instagram-stories.svg";
import { metrikaTargetClick } from "services";
import { UserLogin, LocalesSelector, WebAppProfile } from "containers";
// import WhatsApp from "containers/WhatsApp";
import LogoElement from "./common/logo";
import { useTranslate } from "react-redux-multilingual";

const Header = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { headerSpaceHeight, setHeaderSpaceHeight } = props;
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    /*
    window.addEventListener("scroll", handleScroll);
    setTimeout(handleScroll, 1000);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    */
  }, []);

  const offsetRef = useRef(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const translate = useTranslate();
  const scrolledY = useRef([]);

  const limit = 1;

  const clearScrollTimeout = useRef(null);

  const calculateDirection = (number, maxHeight) => {
    const { current } = scrolledY;
    const { length } = current;
    if (length > limit) {
      // если направление больше 0, то крутим вниз, иначе - крутим вверх
      const distance = current[1] - current[0];
      let offset = offsetRef.current;

      // если направление больше 0, то есть крутим вниз, значит нам следует
      // постепенно уменьшать смещение, пока оно не станет превышать минусовую высоту
      // offsetHeight
      if (distance > 0 && number > maxHeight / 2) {
        offsetRef.current = Math.max(offset - distance, -maxHeight);
      } else if (
        (distance < -maxHeight / 4 && offset >= -maxHeight) ||
        (distance < 0 && offset > -maxHeight) ||
        number < maxHeight
      ) {
        // если вращаем вверх, то увеличиваем сдвиг, пока он не станет равным 0 (то есть упрется в потолок)
        // это вычисление небольшой хак, обеспечивающий плавность хода, и корректно восстанавливающий значение
        // в начале координат
        // это выбор лучшей позиции среди абсолютного сдвига по странице, и планируемого смещения
        // если мы находимся близко к верхнему краю, то корректно восстанавливает заголовок в начале координат
        const bestPosition = Math.max(offset - distance / 1.5, -number);
        offsetRef.current = Math.min(bestPosition, 0);
      }
      // увеличим значение сдвига для заголовка
      setScrollOffset(offsetRef.current);
      // применим смещение для позицированирования релевантных блоков относительно указанного сдвига
      setHeaderSpaceHeight(maxHeight + offsetRef.current);
    }
  };

  const handleScroll = (event) => {
    let number =
      window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    const { current: sticky } = stickyHeader; // document.getElementById("sticky");
    const { offsetHeight = 0 } = sticky || {};

    scrolledY.current = [...scrolledY.current.slice(-limit), number];
    calculateDirection(number, offsetHeight);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const stickyHeader = useRef(null);
  return (
    <div style={{ position: "relative" }}>
      {/* <MobileMenu /> */}
      {/* <div id="header-spacing" style={{ height: 0 * _.get(stickyHeader.current, "offsetHeight") }} /> */}
      <div id="header-spacing" style={{ height: 0 }} />
      <div
        id="sticky-header"
        /*
        className="bg-white"
        style={{
          position: "fixed",
          width: "100%",
          top: scrollOffset,
          left: 0,
          zIndex: 990,
        }}
        */
      >
        <header ref={stickyHeader}>
          {isLoading ? <Pace color="#27ae60" /> : null}

          <div className="container">
            <div className="row py-4">
              <div className="col-lg-3 col-sm-hidden"></div>
              <div className="col my-auto">
                <div className="row">
                  <div className="col-2 my-auto text-left">
                    <LocalesSelector />
                  </div>
                  <div className="col my-auto  text-center">
                    <LogoElement {...props} />
                  </div>
                  <div className="col-2 my-auto text-right">
                    <div>{props.webApp ? <WebAppProfile /> : <UserLogin />}</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-hidden"></div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  whatsapp_message: state.whatsapp.message,
  headerSpaceHeight: state.navi.headerSpaceHeight,
});

export default connect(mapStateToProps, { setHeaderSpaceHeight })(Header);
