import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import { useTranslate } from "react-redux-multilingual";
import { fileUrl } from "reducers/api";

import Premium from "../data/premium";
import { GiftsBlock } from "../data/gifts";

const WebAppShop = (props) => {
  const { settings } = props.env.www;
  const translate = useTranslate();
  const { premium } = props.sessionStore;
  return (
    <div className="text-center mb-5">
      <div className="large-header mb-3">
        <LocalesValues values={settings.shopScreenTitle} defaultValue={translate("shop")} />
      </div>
      <div className="standart-header mb-4">
        <LocalesValues values={settings.shopScreenText} />
      </div>
      {settings.premiumImageId ? (
        <div className="mb-4">
          <img src={fileUrl(settings.premiumImageId)} style={{ maxWidth: "90%" }} />
        </div>
      ) : null}

      <div className="pt-2" />

      {premium ? null : (
        <div className="pb-4">
          <Premium />
        </div>
      )}
      <GiftsBlock />

      {settings.allowMerchShop ? (
        <div className="pt-4">
          <div className="large-header pt-2 mb-3">
            <LocalesValues values={settings.shopMerchTitle} />
          </div>
          <div className="standart-header mb-4">
            <LocalesValues values={settings.shopMerchText} />
          </div>
          {settings.merchImageId ? (
            <div className="mb-4">
              <img src={fileUrl(settings.merchImageId)} style={{ maxWidth: "90%" }} />
            </div>
          ) : null}

          <div className="pt-2" />
          <a href={settings.shopMerchUrl} target="_blank" className="btn btn-solid">
            <i className="fa fa-tshirt mr-2" />
            <LocalesValues values={settings.shopMerchButton} default={translate("shop_merch")} />
          </a>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(WebAppShop);
