import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useTranslate } from "react-redux-multilingual";

import WebApp from "@twa-dev/sdk";
import SettingsModal from "components/standart/settings-modal";
import WebAppUserpic from "components/standart/webapp-userpic";

const WebAppProfile = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const [openModal, setOpenModal] = useState(null);

  return (
    <>
      {sessionStore.account && WebApp.initDataUnsafe.user ? (
        <div style={{ marginTop: "5px" }}>
          <button className="btn btn-sm" onClick={() => setOpenModal(!openModal)}>
            <WebAppUserpic size={35} />
          </button>
          <SettingsModal {...{ openModal, setOpenModal, webApp: true }} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(WebAppProfile);
