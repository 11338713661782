import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, scrollTop, ShowPager } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import LoadingModal from "components/standart/loading-modal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import ShareButton, { contentId } from "components/standart/share-button";
import WebAppShareButton, { contentId } from "components/standart/webapp-share-button";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import MemeImage from "components/standart/memeImage";
import Typewriter from "typewriter-effect";
import ShowToday from "components/standart/showToday";
import nl2br from "react-nl2br";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import { fileUrl } from "reducers/api";

import { customerApi } from "reducers/api";

import AddFriend from "./addFriend";
import NextDay from "../nextDay";
import EditFriendsBlock from "./friendsData";
import { FriendsNamesInfo } from "./friendsDataElements";
import PremiumBlock from "../premium";

const { api } = customerApi.actions;
SwiperCore.use([Pagination]);

const FriendsBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const { settings } = props.env.www;

  const { locales } = useSelector((state) => state);
  const [lockSwiper, setLockSwiper] = useState(false);
  const [contents, setContents] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);
  const [noPremium, setNoPremium] = useState(null);

  const [aspectText, setAspectText] = useState(null);
  const [shortText, setShortText] = useState("");
  const [fullText, setFullText] = useState("");
  const [swiper, setSwiper] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [lastLoaded, setLastLoaded] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  const swiperRef = useRef(null);
  useEffect(() => {
    swiperRef.current = swiper;
  }, [swiper]);

  useEffect(() => {
    if (contents.length) {
      setCurrentContent(contents[currentIndex]);
    } else {
      setSwiper(null);
      setCurrentContent(null);
    }
  }, [lastLoaded, currentIndex]);

  useEffect(() => {
    setNoPremium(!sessionStore.premium);
  }, [sessionStore.premium]);

  useEffect(() => {
    if (currentContent && !lockSwiper) {
      const { usedPlanet, usedAstroAspect } = currentContent.compatibility.aspectOverlap;
      const { icon } = currentContent.compatibility.aspectValue;
      if (usedAstroAspect) {
        setAspectText(
          translate("moon_aspect_text", {
            icon,
            aspect: translate(`aspects_pred_${usedAstroAspect}`),
            planet: translate(`planets_tvor_${usedPlanet}`),
          }),
        );
      } else {
        setAspectText(
          translate("moon_aspect_default", { planet: translate(`planets_${usedPlanet}`) }),
        );
      }

      // setCurrentText(
      //   getLocaledValue(currentContent.daily.gradientText.localesText, locales.data?._id),
      // );
      const shortText = getLocaledValue(
        currentContent.compatibility.aspectsCompatibility.localesTextDown,
        locales.data?._id,
      );
      const text = getLocaledValue(
        currentContent.compatibility.aspectsCompatibility.localesText,
        locales.data?._id,
      );
      // const upText = getLocaledValue(
      //   currentContent.compatibility.aspectsCompatibility.localesTextUp,
      //   locales.data?._id,
      // );
      // const downText = getLocaledValue(
      //   currentContent.compatibility.aspectsCompatibility.localesTextDown,
      //   locales.data?._id,
      // );
      setShortText(`${text}`);
      setFullText(`${text}`);

      if (!currentContent.compatibility.aspectsCompatibility.memeImageUrl) {
        // отключаем пока эту логику
        // loadContentElement(currentContent.compatibility._id);
      }
    }
  }, [currentContent, lockSwiper]);

  const resetSwiper = useCallback(() => {
    const swipeTo0 = () => () => {
      swiperRef.current && swiperRef.current.slideTo(0);
    };
    setTimeout(swipeTo0(), 100);
  }, [swiper, swiperRef]);

  const loadContent = useCallback(() => {
    props.getContent(
      {},
      {
        onSuccess(body) {
          setContents(body);
          setLoaded(true);
          setLastLoaded(new Date());
          setContentLoading(false);
          resetSwiper();
        },
        onRequest() {
          setContentLoading(true);
          // setIsLoaded(true);
        },
        onFailure() {
          setContentLoading(false);
        },
      },
    );
  }, [swiper, swiperRef]);

  const updateSwiperSize = useCallback(() => {
    // swiper.updateSize();
    swiper.updateAutoHeight(200);
  }, [swiper]);

  const loadContentElement = useCallback(
    (contentId) => {
      props.getContentElement(
        { query: { contentId, localeId: locales.data?._id } },
        {
          onSuccess(compatibility) {
            // заменим содержимое в списке по факту получения id картинки
            setContents(
              _.map(contents, (content) =>
                content.compatibilityId === compatibility._id
                  ? { ...content, compatibility }
                  : content,
              ),
            );
            setTimeout(() => {
              updateSwiperSize();
            }, 500);
          },
          onRequest() {
            // setIsLoaded(true);
          },
        },
      );
    },
    [contents, swiper],
  );

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    loadContent();
  }, [props.friendsRefresh]);

  return isLoaded ? (
    <div className="text-center">
      {/* 
      <div className="standart-content mb-3">
        <ShowToday />
      </div>
      */}

      {/* <div className="mb-3 large-header">{translate("friends")}</div> */}
      {currentContent && (
        <div className="mt-2 mb-4">
          <FriendsNamesInfo
            customer={sessionStore.account}
            friend={currentContent.anotherCustomer || currentContent}
          />
        </div>
      )}

      <div className="position-relative">
        {contents.length ? (
          <>
            {/* 
            <div
              className="px-1 py-0 rounded standart-header"
              style={{
                position: "absolute",
                zIndex: 10,
                top: 0,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, .38)",
                color: "white",
                fontSize: "0.9rem",
              }}
            >
              {`${currentIndex + 1}/${contents.length + 0}`}
            </div>
           */}

            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              autoHeight={true}
              observer={true}
              observeSlideChildren={true}
              pagination={false}
              onSwiper={setSwiper}
              onSlideChange={slideChangeHandler}
            >
              {contents.map((dailyContent, index) => {
                return (
                  <SwiperSlide key={dailyContent._id}>
                    <div className="text-center">
                      {/* 
                      {dailyContent.compatibility.aspectsCompatibility?.meme?.file?.location ? (
                        <MemeImage
                          url={dailyContent.compatibility.aspectsCompatibility.meme.file.location}
                          loadHandler={updateSwiperSize}
                        />
                      ) : (
                        <div className="giphy-image pt-5">
                          <LoadingIcon className="mt-5" />
                        </div>
                      )}
                       */}

                      {dailyContent.compatibility.aspectsCompatibility.memeImageUrl ? (
                        <MemeImage
                          url={dailyContent.compatibility.aspectsCompatibility.memeImageUrl}
                          loadHandler={updateSwiperSize}
                        />
                      ) : (
                        <div className="giphy-image pt-5">
                          <LoadingIcon className="mt-5" />
                        </div>
                      )}

                      {/* 
                      {dailyContent.daily.imageData ? (
                        <GiphyImage {...dailyContent.daily.imageData} />
                      ) : (
                        <div className="giphy-image pt-5">
                          <LoadingIcon className="mt-5" />
                        </div>
                      )}
                       */}
                    </div>
                  </SwiperSlide>
                );
              })}
              {/* 
              <SwiperSlide>
                <div className="giphy-image pt-5">
                  <NextDay
                    {...sessionStore}
                    isVisible={currentIndex === contents.length}
                    className="mt-3"
                  />
                </div>
              </SwiperSlide>
              */}
            </Swiper>

            <div className="pt-2 pb-3">
              <ShowPager total={contents.length + 0} current={currentIndex} swiper={swiper} />
            </div>

            {currentContent && (
              <div>
                <div className="position-relative">
                  <div className="content-html position-relative">
                    {false && noPremium ? (
                      <div
                        className="position-absolute w-100 h-100"
                        style={{ zIndex: 99, top: 0, left: 0 }}
                      >
                        <div className="d-flex align-items-stretch h-100 w-100">
                          <PremiumBlock asLock={true} />
                        </div>
                      </div>
                    ) : null}

                    <div style={{ filter: noPremium ? "blur(4px)" : "" }}>
                      <div className="mb-3">{nl2br(shortText)}</div>

                      <div>{aspectText}</div>
                    </div>
                  </div>
                </div>

                <div className="pt-4">
                  <div className="pt-2" />

                  {/* <WebAppShareButton share={"friends"} contentId={currentContent.compatibilityId} /> */}
                  {
                    /* currentSize < settings.freeFriends || */
                    sessionStore.premium ? (
                      <div>
                        <div className="">
                          <div>
                            <button type="button" className="btn btn-solid" onClick={toggleModal}>
                              <i className="fa fa-book mr-2" />
                              {translate("read_more")}
                            </button>
                          </div>
                          {openModal && (
                            <Modal
                              centered
                              wrapClassName="fullscreen-modal"
                              isOpen={openModal}
                              size="md"
                              toggle={toggleModal}
                            >
                              <ModalHeader toggle={toggleModal}></ModalHeader>
                              <ModalBody>
                                <Col3Container>
                                  <div className="text-center pr-3">
                                    <div className="mb-4">
                                      <FriendsNamesInfo
                                        customer={sessionStore.account}
                                        friend={currentContent.anotherCustomer || currentContent}
                                      />
                                    </div>

                                    {/* 
                                    <MemeImage
                                      url={
                                        currentContent.compatibility.aspectsCompatibility.meme.file
                                          .location
                                      }
                                      loadHandler={updateSwiperSize}
                                    />
                                    */}
                                    <MemeImage
                                      url={
                                        currentContent.compatibility.aspectsCompatibility
                                          .memeImageUrl
                                      }
                                    />

                                    <div className="pb-3" />
                                    <div className="my-3">{nl2br(fullText)}</div>
                                    <div>{aspectText}</div>
                                  </div>
                                </Col3Container>
                                <BottomNaviSpacing />
                              </ModalBody>
                            </Modal>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="position-relative">
                        <PremiumBlock buttonIcon={"fa-lock"} buttonText={translate("read_more")} />
                      </div>
                    )
                  }
                  <div className="pt-4" />
                  <WebAppShareButton
                    share={"compatibility"}
                    contentId={currentContent.compatibilityId}
                  />

                  {/* <ShowShare currentId={currentDaily._id} /> */}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="py-2">
            {settings.noFriendsImageId ? (
              <div className="mb-4">
                <img src={fileUrl(settings.noFriendsImageId)} width="100%" />
              </div>
            ) : null}
            <LocalesValues values={settings.noFriendsText} isHtml={false} defaultValue={""} />
            <div className="mt-4" />
            <AddFriend successHandler={loadContent} currentSize={contents.length} />
          </div>
        )}

        {contents.length ? (
          <div className="mt-4">
            <div className="pt-4" />
            <EditFriendsBlock
              friends={contents}
              refreshHandler={loadContent}
              lockHandler={setLockSwiper}
              isLocked={contentLoading}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="p-5 text-center">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
  friendsRefresh: state.navi.friendsRefresh,
});

export default connect(mapStateToProps, {
  getContent: api.get("/account/webapp/friends"),
  getContentElement: api.get("/account/webapp/friends/info"),
})(FriendsBlock);
