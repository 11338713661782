import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { metrikaTargetClick } from "services";
import { scrollTop, LogoImage } from "components/standart";
import { Link } from "react-router-dom";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import giphyLogo from "assets/images/powered-giphy.png";

const Footer = (props) => {
  const { translate, navi } = props;

  const { settings = {} } = props.env.www || {};

  return (
    <footer>
      <section className="footer-section">
        <div className="container">
          <div className="standart-header text-center text-white py-4">
            <div className="mb-2">
              <div className="mx-auto my-auto d-flex justify-content-center">
                {props.webApp && settings.logoImageId ? (
                  <div className="text-right my-auto mr-2 pt-0">
                    <Link to={props.webApp ? "/app" : "/"}>
                      <LogoImage />
                    </Link>
                  </div>
                ) : null}
                <div className="text-left my-auto">
                  <div style={{ fontSize: "1.3em", fontFamily: "Arial Black", fontWeight: "bold" }}>
                    <Link
                      to={props.webApp ? "/app" : "/"}
                      onClick={scrollTop}
                      className="text-white text-decoration-none"
                    >
                      {props.webApp ? "astromeme" : "astrogotchi"}
                    </Link>
                  </div>

                  {props.webApp ? (
                    <div
                      style={{ fontSize: "0.77em", fontFamily: "Arial", marginTop: "-4px" }}
                      className="text-lowercase"
                    >
                      {translate("sitename_helper")}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {props.webApp ? (
              <div>
                <div className="my-4" />
                {settings.telegramChannelUrl && (
                  <a
                    href={settings.telegramChannelUrl}
                    className="text-white mx-2"
                    target="_blank"
                    // data-id="instagram"
                    // onClick={metrikaTargetClick}
                  >
                    <i className="fab fa-telegram-plane mr-2" />
                    {translate("telegram_channel")}
                  </a>
                )}

                {settings.telegramCommunityUrl && (
                  <a
                    href={settings.telegramCommunityUrl}
                    className="text-white mx-2"
                    target="_blank"
                    // data-id="instagram"
                    // onClick={metrikaTargetClick}
                  >
                    <i className="fab fa-telegram-plane mr-2" />
                    {translate("telegram_community")}
                  </a>
                )}
              </div>
            ) : (
              <div>
                <div className="my-3" />

                <div style={{ fontSize: "1.8em" }}>
                  {settings.instagram && (
                    <a
                      href={settings.instagram}
                      className="text-white mr-4"
                      target="_blank"
                      data-id="instagram"
                      onClick={metrikaTargetClick}
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  )}
                  {settings.facebook && (
                    <a
                      href={settings.facebook}
                      className="text-white mr-4"
                      target="_blank"
                      data-id="facebook"
                      onClick={metrikaTargetClick}
                    >
                      <i className="fab fa-facebook" />
                    </a>
                  )}

                  {settings.vk && (
                    <a
                      href={settings.vk}
                      className="text-white mr-4"
                      target="_blank"
                      data-id="vk"
                      onClick={metrikaTargetClick}
                    >
                      <i className="fab fa-vk" />
                    </a>
                  )}

                  {settings.whatsapp && (
                    <a
                      href={settings.whatsapp}
                      className="text-white mr-4"
                      target="_blank"
                      data-id="whatsapp"
                      onClick={metrikaTargetClick}
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                  )}

                  {settings.telegram && (
                    <a
                      href={settings.telegram}
                      className="text-white mr-4"
                      target="_blank"
                      data-id="telegram"
                      onClick={metrikaTargetClick}
                    >
                      <i className="fab fa-telegram" />
                    </a>
                  )}

                  {settings.email && (
                    <a
                      href={`mailto:${settings.email}`}
                      className="text-white"
                      target="_blank"
                      data-id="email"
                      onClick={metrikaTargetClick}
                    >
                      <i className="far fa-envelope" />
                    </a>
                  )}
                </div>
                <div className="my-2" />

                <div className="pt-2">
                  <a
                    target="_blank"
                    href="/offer"
                    className="text-white py-1"
                    data-id="offer"
                    onClick={metrikaTargetClick}
                  >
                    {translate("offer")}
                  </a>
                </div>
              </div>
            )}

            {props.webApp ? null : (
              <div className="mt-4">
                <a href="https://giphy.com">
                  <img src={giphyLogo} height="30" />
                </a>
              </div>
            )}
          </div>
        </div>
        <BottomNaviSpacing />
        {/* <div className="d-block d-lg-none mobile-menu-space">&nbsp;</div> */}
      </section>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  navi: state.navi,
});

export default connect(mapStateToProps, null)(withTranslate(Footer));
